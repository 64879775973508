import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const PlayIcon = ({ small, playing, onClick, className }) => {
  return (
    <Wrapper small={small} onClick={onClick} className={className}>
      {playing ? (
        <FontAwesomeIcon icon={["fas", "pause"]} />
      ) : (
        <FontAwesomeIcon icon={["fas", "play"]} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #b7b7b7;
  cursor: pointer;

  & > svg > path {
    fill: #e2e2e2;
  }

  ${(props) =>
    props.small
      ? `
    width: 24px;
    height: 24px;
    font-size: 10px;
    border-radius: 12px;
    border-width: 2px;

    & > svg {
      margin-left: 1px;
    }
  `
      : `
    width: 68px;
    height: 68px;
    border-radius: 34px;
    font-size: 28px;

    & > svg {
      margin-left: 6px;
    }
  `}
`;
