import { useContext } from "react";
import { EventContext } from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { Video, VideoChat, Heartbeater } from "Components";

export const Show = () => {
  const { event } = useContext(EventContext);

  // const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  // const storageDirectory = event.meta.storageDirectory;

  // const { backgrounds } = event.register;
  //const backgroundImg = `${api}/o/${storageDirectory}%2F${backgrounds[1]}?alt=media`;

  return (
    <>
      <Layout
        // header
        showHeader
        // main
        heroContent={
          event?.show?.chatActive ? (
            <VideoChat
              videoId={event?.show?.videoId}
              chatId={event?.show?.chatId}
              videoMeta={false}
            />
          ) : (
            <Video id={event?.show?.videoId} />
          )
        }
        heading={event?.show?.heading}
        subHeading={event.show?.subHeading}
        body={event?.show?.body}
        flexGrow
        // footer
        showFooter
      />
      <Heartbeater />
    </>
  );
};
