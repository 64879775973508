import styled from "styled-components";
import { FieldMessage, Input } from "Components";

export const DOBField = ({
  onChange,
  value = {},
  field,
  error = {},
  ageGate,
  ...props
}) => {
  const handleText = (value, name) => {
    onChange({ [field.name]: value });
  };

  return (
    <>
      <DOBFields>
        <FieldLabel>Birthdate</FieldLabel>

        <DOBPart>
          <Input
            placeholder={"MM"}
            onChange={(fieldValue) =>
              handleText({ ...value, MONTH: fieldValue })
            }
            value={value["MONTH"]}
            error={error.status && error.status}
            maxLength={2}
            type="tel"
          />
        </DOBPart>

        <DOBPart>
          <Input
            placeholder={"DD"}
            onChange={(fieldValue) => handleText({ ...value, DAY: fieldValue })}
            value={value["DAY"]}
            error={error.status && error.status}
            maxLength={2}
            type="tel"
          />
        </DOBPart>

        <DOBPart>
          <Input
            placeholder={"YYYY"}
            onChange={(fieldValue) =>
              handleText({ ...value, YEAR: fieldValue })
            }
            value={value["YEAR"]}
            error={error.status && error.status}
            maxLength={4}
            type="tel"
          />
        </DOBPart>
      </DOBFields>

      <FieldMessage active={!!error.status} content={error.message} />
    </>
  );
};

const DOBFields = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FieldLabel = styled.div`
  font-size: 17px;
  text-transform: uppercase;
  color: #ffffff;
`;

const DOBPart = styled.div`
  padding: 0 0 0 2%;
`;
