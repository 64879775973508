import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const PlayIconCircle = ({
  playing,
  onClick,
  className,
  small,
  transparent,
}) => {
  return (
    <Wrapper
      onClick={onClick}
      className={className}
      small={small}
      transparent={transparent}
    >
      {playing ? (
        <FontAwesomeIcon icon={["fa", "pause-circle"]} />
      ) : (
        <FontAwesomeIcon icon={["fa", "play-circle"]} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > svg > path {
    fill: ${(props) =>
      props.transparent ? `rgba(255,255,255,.5)` : `#ffffff`};
  }

  ${(props) =>
    props.small
      ? `font-size: 36px;`
      : `
    font-size: 60px;
  `}
`;
