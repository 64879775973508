import { useState, useContext, FC } from "react";
import styled from "styled-components";
// @ts-ignore
import { GridColumn, Label, Footnote, LoaderInline } from "notes";
import { FieldMessage, RingSpinner } from "Components";
import { PhoneMask } from "Components/Form/InputMasks/PhoneMask";
import { MessageContext, UserContext } from "context";

interface Props {
  showLabel: string;
  buttonText: string;
}

export const TextReminder: FC<Props> = ({ showLabel, buttonText }) => {
  const {
    user,
    userType,
    userSubmissionDoc,
    writeToUserSubmissionDoc,
  } = useContext(UserContext);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const defaultNumber = urlParams.get("phone");

  const [loading, setLoading] = useState(false);
  const { displayMessage } = useContext(MessageContext);
  const [phoneValue, setPhoneValue] = useState(defaultNumber || "");
  const [error, setError] = useState("");

  const isReminderSet = userSubmissionDoc?.get("OPTINPHONE") === true;

  if (!user || !userType) return <RingSpinner />;

  const confirmationMsg =
    "Your reminder and link will be sent on the day of the show!";

  const handleSubmit = async () => {
    if (!!phoneValue && phoneValue.length >= 10) {
      setLoading(true);
      try {
        await writeToUserSubmissionDoc({
          PHONE: phoneValue,
          OPTINPHONE: true,
        });
        setLoading(false);
        displayMessage({
          color: "green",
          children: confirmationMsg,
          timeout: 20000,
        });
      } catch (error) {
        setLoading(false);
        // @ts-ignore
        setError(error.message);
      }
    } else {
      setError("You must enter a valid phone number to receive a reminder.");
    }
  };

  return (
    <GridColumn>
      {isReminderSet ? (
        <>{confirmationMsg}</>
      ) : (
        <>
          {showLabel && <StyledLabel>Mobile Phone Number</StyledLabel>}
          <StyledPhone
            phoneNumber={phoneValue}
            onChange={(value: string) => {
              setPhoneValue(value);
              setError("");
            }}
            error={!!error}
            placeholder="Phone Number"
          />
          <FieldMessage
            active={!!error}
            content={error}
            type={error ? "error" : "success"}
          />
          <StyledFootnote>
            Text me a reminder 30 minutes before the show with my ticket link.
            Message and data rates may apply. Consent is not a condition of
            purchase. Text back STOP to cancel.
          </StyledFootnote>
          <Button disabled={loading} onClick={handleSubmit}>
            {loading ? (
              <LoaderInline />
            ) : buttonText ? (
              buttonText
            ) : (
              "Text me a reminder!"
            )}
          </Button>
        </>
      )}
    </GridColumn>
  );
};

const StyledLabel = styled(Label)`
  margin-bottom: 4px;
`;

const StyledPhone = styled(PhoneMask)`
  width: 100%;
  color: #000000;
  border-color: #bfbfbf;
`;

const StyledFootnote = styled(Footnote)`
  margin: 8px 0 0;
  color: #707070;
  font-size: 11px;
  font-weight: 400;
`;

const Button = styled.button`
  margin-top: 24px;
  background-color: #ea1300;
  color: #ffffff;
  text-decoration: none;
  border-radius: 50px;
  padding: 10px 35px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: clamp(18px, 3vw, 24px);
  font-weight: 700;
  outline: 0;
  border: 0;
  align-self: start;

  &:hover,
  &:focus {
    background-color: #b91304;
  }
`;
