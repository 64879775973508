import styled from "styled-components";

export const VideoMeta = ({ body }) => {
  return (
    <VideoMetaWrapper>
      <div>{body}</div>
    </VideoMetaWrapper>
  );
};

const VideoMetaWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
