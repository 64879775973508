import styled from "styled-components";
import { Input } from "Components";

export const PhoneMask = ({
  phoneNumber: value,
  onChange,
  placeholder,
  ...props
}) => {
  return (
    <Container>
      <Input
        type="tel"
        placeholder={placeholder}
        maxLength={16}
        onChange={(value) =>
          onChange(value.length ? getFormattedPhoneNumber(value) : "")
        }
        value={value}
        {...props}
      />
    </Container>
  );
};

export const getFormattedPhoneNumber = (input) => {
  let output = "(";
  input.replace(/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function (
    match,
    g1,
    g2,
    g3
  ) {
    if (g1.length) {
      output += g1;
      if (g1.length === 3) {
        output += ")";
        if (g2.length) {
          output += " " + g2;
          if (g2.length === 3) {
            output += " - ";
            if (g3.length) {
              output += g3;
            }
          }
        }
      }
    }
  });
  return output;
};

const Container = styled.div`
  display: flex;
  position: relative;
`;
