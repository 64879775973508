import React, { useContext, useState, useRef } from "react";
import styled from "styled-components";
import { ChatContext } from "Components";
import { GridColumn, GridRow, Input, Icon, withDefaultMedia } from "notes";

export const ChatBox = withDefaultMedia(({ matches }) => {
  const [value, setValue] = useState("");
  const [emojis, setEmojis] = useState(false);
  const { sendMessage } = useContext(ChatContext);

  const send = () => {
    sendMessage(value);
    setValue("");
  };

  const inputRef = useRef();

  const addEmoji = (emoji) => {
    const newMessage = `${value || ""} ${emoji}`;
    setValue(newMessage);
    setEmojis(false);
    inputRef.current.focus();
  };

  const emojiArray = [
    // "", // Pickup Truck
    "", // Grinning
    "", // Blush
    "", // Joy
    "", // Smiley with Hearts
    "", // Star Struck
    "", // Partying Face
    "", // Mind Blown
    // "", // Smiley with Cowboy Hat
    "", // Smiling Face with Heart Eyes
    "", // Red Heart
    "", // Cry
    "", // Raised Hands
    "", // Clap
    "", // Fire
    "", // Microphone
    "", // Music Notes
    "", // Headphones
  ];

  return (
    <Wrapper>
      <StyledInput
        value={value}
        onChange={setValue}
        onKeyDown={(e) => {
          e.which === 13 && send();
        }}
        placeholder="Comment..."
        leftIcon={
          matches.large && (
            <EmojiIcon
              onClick={() => setEmojis(!emojis)}
              selector
              name="Emoji"
            />
          )
        }
        rightIcon={
          <StyledIcon
            onClick={send}
            selector
            name="Send"
            style={{ cursor: "pointer" }}
          />
        }
        style={{ borderRadius: 0 }}
        ref={inputRef}
      />
      <EmojiContainer active={emojis}>
        <EmojiInner>
          {emojiArray.map((item) => (
            <Emoji onClick={() => addEmoji(item)}>{item}</Emoji>
          ))}
        </EmojiInner>
      </EmojiContainer>
    </Wrapper>
  );
});

const Wrapper = styled(GridColumn)``;

const StyledInput = styled(Input)`
  border-radius: 0;
  border: none;
  border-top: 1px solid ${(props) => props.theme.palette.gray.lighter};
  padding-left: 15px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding-left: 40px;
  }
`;

const StyledIcon = styled(Icon)`
  width: 33px;
  height: 33px;
  margin-top: -9px;
`;

const EmojiIcon = styled(Icon)`
  cursor: pointer;
  margin-top: -4px;
  margin-left: -5px;
  display: none;
  visibility: hidden;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: block;
    visibility: visible;
  }
`;

const EmojiContainer = styled(GridColumn)`
  background-color: #fafafa;
  height: ${(props) => (props.active ? "104px" : 0)};
  opacity: ${(props) => (props.active ? 1 : 0)};
  overflow: hidden;
  transition: all 0.2s ease;
  visibility: ${(props) => (props.active ? "visible" : "hidden")};
`;

const EmojiInner = styled(GridRow)`
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 9px 6px 6px 3px;
`;

const Emoji = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  font-size: 32px;
  line-height: 49px;
  margin: -10px 6px 6px;
  width: 32px;
  &:hover {
    text-shadow: 0 0 5px rgb(0 0 0 / 32%);
  }
`;
