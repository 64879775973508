import { FieldMessage, Input } from "Components";

export const StateField = ({
  onChange,
  value,
  field,
  error = {},
  ...props
}) => {
  const handleText = (value, e) => {
    onChange({ [field.name]: value.toUpperCase() });
  };

  const keyPress = (e) => !e.key[0].match(/[A-Za-z]/g) && e.preventDefault();

  return (
    <>
      <Input
        placeholder={field.placeholder || "State"}
        onChange={(value, e) => handleText(value, e)}
        onKeyPress={keyPress}
        value={value}
        error={!!error.status}
        name={field.name}
        maxLength={2}
        {...props}
      />
      <FieldMessage active={!!error.status} content={error.message} />
    </>
  );
};
