import styled from "styled-components";
import { Icon } from "notes";

export const FieldMessage = ({
  content = "",
  type,
  active = true,
  ...props
}) => {
  return active ? (
    <ErrorMessageContainer {...props}>
      {type === "success" && props.field?.type !== "select" && (
        <SuccessIcon indicator name="Check" />
      )}

      {type === "error" && (
        <>
          <ErrorIcon form name="Error" />
          <Message>{content}</Message>
        </>
      )}
    </ErrorMessageContainer>
  ) : null;
};

const ErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
`;

const Message = styled.p`
  color: #e45c52;
  font-size: 12px;
  margin: 0;
`;

const ErrorIcon = styled(Icon)`
  display: inline-flex;
  flex-shrink: 0;
  margin-right: 12px;
  position: relative;
  width: 12px;
  display: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: block;
  }
`;

const SuccessIcon = styled(ErrorIcon)`
  path {
    fill: #008200;
  }
`;
