import styled from "styled-components";
import { MaxWidthContainer } from "./MaxWidthContainer";

export const Hero = ({ heroContent }) => {
  return (
    heroContent && (
      <HeroWrapper id="hero">
        <HeroContainer>{heroContent}</HeroContainer>
      </HeroWrapper>
    )
  );
};

export const HeroWrapper = styled.div`
  background-color: #1d1b1a;
  color: #ffffff;
  padding: 0 15px;
`;

export const HeroContainer = styled(MaxWidthContainer)`
  height: 56.25vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;
