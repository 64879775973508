import { useEffect, useContext, useState, FC } from "react";
import { firebase, UserContext, UserType } from "context";
import { RingSpinner } from "Components/RingSpinner";
// @ts-ignore
import { Redirect } from "react-router-dom";

interface Props {
  match: {
    params: {
      shortCode: string;
    };
  };
}

export const ShortCode: FC<Props> = (props) => {
  const [response, setResponse] = useState(false);
  const { userType, user, refreshUserClaims } = useContext(UserContext);
  const { shortCode } = props.match.params;

  useEffect(() => {
    const verify = async () => {
      await firebase.functions().httpsCallable("shortUrl-verify")({
        shortCode,
      });
      await refreshUserClaims();
      setResponse(true);
    };

    if (shortCode && user) {
      verify();
    }
  }, [shortCode, user, refreshUserClaims]);

  // Skips if user is already registered
  if (userType && userType > UserType.UNREGISTERED) {
    return <Redirect to="/" />;
  }

  return response ? <Redirect to="/" /> : <RingSpinner />;
};
