import { createContext, useContext } from "react";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import { EventContext, UserContext, firebase } from "context";

export const ChatContext = createContext();
export const ChatConsumer = ChatContext.Consumer;
export const ChatProvider = ({ children }) => {
  const { event } = useContext(EventContext);
  const { user } = useContext(UserContext);
  const [messages, loading] = useCollection(
    firebase
      .firestore()
      .collection(`events/${event?.id}/chat`)
      .where("status", "in", ["1", user?.uid])
      .orderBy("timestamp")
      .limitToLast(100)
  );

  const [userInfo] = useDocumentData(
    firebase.firestore().doc(`events/${event.id}/users/${user.uid}`)
  );

  const sendMessage = async (message) => {
    const user = firebase.auth().currentUser;
    if (!user) {
      throw new Error("You must be logged in to call this function");
    }
    const batch = firebase.firestore().batch();
    const userDoc = firebase
      .firestore()
      .doc(`events/${event.id}/users/${user.uid}`);
    const chatDoc = firebase
      .firestore()
      .collection(`events/${event.id}/chat`)
      .doc();
    batch.set(
      userDoc,
      {
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    );
    batch.set(chatDoc, {
      message: message,
      status: user.uid,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      uid: user.uid,
      username: userInfo.username,
    });
    try {
      await batch.commit();
      return true;
    } catch (error) {
      console.log(error.message);
    }
    return false;
  };

  if (loading) {
    return null; //TODO: loading strategy
  }

  return (
    <ChatContext.Provider
      value={{
        messages,
        sendMessage,
        username: userInfo?.username,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
