import React, { useContext } from "react";
import styled from "styled-components";
import { EventContext } from "context";

export const Video = React.memo(({ id, loop }) => {
  const { event } = useContext(EventContext);

  return (
    <Container>
      <Overlay>
        {event.show.livestreamDown ? (
          <Header>{event.show.livestreamDownMessage}</Header>
        ) : (
          <iframe
            title="Livestream"
            src={`https://player.vimeo.com/video/${id}?autoplay=1&loop=${
              loop ? "1" : "0"
            }&amp;app_id=122963`}
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        )}
      </Overlay>
    </Container>
  );
});

const Container = styled.div`
  position: relative;
  padding-top: 56.25%;
  background-color: #151515;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  text-align: center;
`;

const Header = styled.h2`
  color: #ffffff;
  font-size: 24px;
`;
