import { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext } from "context";
import { ButtonPrimary } from "Components/Buttons";

export const WinningArtistCallout = () => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;

  const { artists, winner } = event.voting.localArtists;
  const winningArtist = artists.find((artist) => artist.winner);

  let {
    artist: artistName,
    // bio,
    // bioVideo,
    image,
    // social,
    // song,
    // winner: contestWinner,
  } = winningArtist;

  let artistImage = `${api}/o/${storageDirectory}%2F${image}?alt=media`;
  const { heading, body, registerBtn } = winner;

  const handleRegisterAnchorLink = () => {
    logClick({
      label: "Register Anchor Link",
      url: "#register",
    });
  };

  return (
    <>
      {winningArtist && (
        <Wrapper>
          <ArtistImage background={artistImage} />
          <Content>
            <Heading>
              {ReactHtmlParser(heading[0])} {artistName}
            </Heading>
            <Body>{ReactHtmlParser(body.replace("{ARTIST}", artistName))}</Body>
            <ButtonPrimary onClick={handleRegisterAnchorLink}>
              {registerBtn}
            </ButtonPrimary>
          </Content>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin: 0 -15px 25px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
    margin: 0 0 25px;
  }
`;

const ArtistImage = styled.div`
  padding-top: 56.25%;

  ${(props) =>
    props.background &&
    `
    background-image: url(${props.background});
    background-size: cover;
    background-position: center;
  `}

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: 55%;
    padding-top: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1336bf;
  padding: 50px 25px;
  text-align: center;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex: 1;
    padding: 50px;
  }
`;

const Heading = styled.h1`
  text-transform: uppercase;
  font-size: 44px;
  margin-bottom: 0px;
`;

const Body = styled.p`
  margin-bottom: 32px;
`;
