import { useContext } from "react";
import styled from "styled-components";
import { GridColumn, H3, H2, P } from "notes";
import { EventContext } from "context";
import { TextReminder } from "Components";
import { PrimaryTemplate as Layout } from "Layout/Templates";

export const Reminder = () => {
  const { event } = useContext(EventContext);

  return (
    <LayoutStyle showHeader flexGrow showFooter>
      <Container yCenter>
        {!!event?.reminder && <Heading>{event?.reminder?.heading}</Heading>}
        <Card>
          <SubHeading>Text Message Reminder</SubHeading>
          <Description>
            Text me a reminder 30 minutes before the show with my ticket link
          </Description>
          <TextReminder showLabel />
        </Card>
      </Container>
    </LayoutStyle>
  );
};

const Card = styled(GridColumn)`
  background-color: #ffffff;
  border-radius: 8px;
  color: #222222;
  padding: 40px 24px 104px 24px;
  position: relative;
  width: 100%;
  max-width: 472px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    border: 1px solid #a6aeb2;
    margin: 24px auto 72px auto;
    padding: 40px;
  }
`;

const SubHeading = styled(H3)`
  color: #222222;
  font-size: 17px;
  margin-bottom: 4px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 25px;
  }
`;

const Description = styled(P)`
  color: #222222;
  font-size: 12px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 17px;
  }
`;

const LayoutStyle = styled(Layout)`
  background-color: #ffffff;
  flex-grow: 1;
  text-align: left;
`;

const Container = styled(GridColumn)`
  height: 100%;
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
  max-width: 472px;
  padding-top: 40px;
`;

const Heading = styled(H2)`
  color: #0065c3;
  font-size: 24px;
  max-width: 100%;
  padding: 0 40px;
  text-align: center;
  text-transform: uppercase;

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    font-size: 32px;
    max-width: 450px;
    padding: 0 24px;
  }
`;
