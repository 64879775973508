import { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, UserContext } from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { HeroContainer } from "Layout/Hero";
import { PostShowSurvey } from "Components/Form";

export const PostShow = () => {
  const { event } = useContext(EventContext);
  const { userType } = useContext(UserContext);

  return (
    <Layout
      // header
      showHeader
      // main
      heroContent={
        <HeroWrapper
          token={!!userType === 1}
          showSurvey={event.postShow?.showSurvey}
        >
          <StyledHeroContainer
            token={!!userType === 1}
            showSurvey={event.postShow?.showSurvey}
          >
            <HeroLeft
              token={!!userType === 1}
              showSurvey={event.postShow?.showSurvey}
            >
              <ThankYouHeader>
                {userType === 1
                  ? ReactHtmlParser(event?.postShow?.thankYouHeading[0])
                  : ReactHtmlParser(event?.postShow?.thankYouHeading[1])}
              </ThankYouHeader>
              <ThankYouBody>
                {userType === 1
                  ? ReactHtmlParser(event?.postShow?.thankYouBody[0])
                  : ReactHtmlParser(event?.postShow?.thankYouBody[1])}
              </ThankYouBody>
            </HeroLeft>

            {event.postShow?.showSurvey && userType === 1 && (
              <HeroRight>
                <PostShowSurvey />
              </HeroRight>
            )}
          </StyledHeroContainer>
        </HeroWrapper>
      }
      heading={event?.postShow?.heading}
      subHeading={event?.postShow?.subHeading}
      body={event?.postShow?.body}
      flexGrow
      mainPadding
      // footer
      showFooter
    />
  );
};

const HeroWrapper = styled.div`
  ${(props) => props.token && props.showSurvey && `padding: 0;`}
`;

const StyledHeroContainer = styled(HeroContainer)`
  ${(props) =>
    props.token &&
    props.showSurvey &&
    `max-width: unset;
    height: unset;
    max-height: unset;
    flex-direction: column;`}
`;

const HeroLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  ${(props) =>
    props.token &&
    props.showSurvey &&
    `height: 56.25vw;
    max-height: 450px;
    justify-content: center;
    padding: 0 15px;
    
    @media only screen and ${props.theme.maxMedia.medium} {
      padding: 0 407px 0 15px;
    }`}
`;

const HeroRight = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-top: 1px solid #18436b;
  border-bottom: 1px solid #18436b;
  background: #102b4e;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: 50px 25px;
  }

  a {
    display: inline-flex;
    background-color: #fec82e;
    color: #000000;
    padding: 10px 35px;
    text-decoration: none;
    border-radius: 5px;
    font-size: clamp(18px, 3vw, 24px);
    text-transform: uppercase;
  }
`;

const ThankYouHeader = styled.h1`
  font-size: clamp(32px, 7vw, 72px);
  line-height: 1;
  margin-bottom: 25px;
  text-transform: uppercase;
`;

const ThankYouBody = styled.p`
  font-size: clamp(20px, 5vw, 28px);
  margin: 0;
  text-transform: uppercase;

  a,
  a:visited {
    color: #000000;
  }
`;
