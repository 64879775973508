import React, { useContext } from "react";
import styled from "styled-components";
import { EventContext } from "context";
import ReactHtmlParser from "react-html-parser";
import { fonts } from "theme/fonts";

export const PoweredBy = () => {
  const { event } = useContext(EventContext);

  return (
    <PoweredByContainer>
      <PoweredByHeading>
        {ReactHtmlParser(event.register.poweredBy)}
      </PoweredByHeading>
    </PoweredByContainer>
  );
};

const PoweredByContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
`;

const PoweredByHeading = styled.h6`
  margin: 0;
  margin-bottom: 5px;
  font-family: ${fonts.brand.medium};
  color: #102b4e;

  span:nth-of-type(1) {
    color: #4580dd;
    font-size: 18px;
    display: block;
  }

  span:nth-of-type(2) {
    font-size: 24px;
  }
`;
