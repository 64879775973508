import styled from "styled-components";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Carousel = ({ children }) => {
  const settings = {
    // autoplay: true,
    // autoplaySpeed: 5000,
    dots: false,
    infinite: true,
    pauseOnHover: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    nextArrow: <IconNext icon="caret-right" />,
    prevArrow: <IconPrevious icon="caret-left" />,
  };

  return (
    <CarouselContainer>
      <Slider {...settings}>{children}</Slider>
    </CarouselContainer>
  );
};

const CarouselContainer = styled.div`
  margin-bottom: 25px;

  .slick-list {
    width: 90%;
    margin: 0 auto;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    height: inherit;
  }

  .slick-slide > div {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .slick-arrow {
    width: unset;
    height: 50px;
    path {
      fill: #000000;
    }
  }
`;

const IconPrevious = styled(FontAwesomeIcon)`
  left: 0px;
`;

const IconNext = styled(FontAwesomeIcon)`
  right: 0px;
`;
