import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "notes";

export const AdminControls = ({ views, selectedView, setAdminView }) => {
  const [hidden, setHidden] = useState(false);
  const history = useHistory();
  if (hidden) return null;

  return (
    <ControlsContainer>
      <ButtonPrimaryStyled onClick={() => setHidden(true)}>
        hide
      </ButtonPrimaryStyled>
      {views.map((view, idx) => (
        <ButtonPrimaryStyled
          disabled={view === selectedView}
          onClick={() => {
            setAdminView(view);
            history.push(`?view=${view}`);
          }}
          key={idx}
        >
          {view}
        </ButtonPrimaryStyled>
      ))}
    </ControlsContainer>
  );
};

const ControlsContainer = styled.div`
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  row-gap: 3px;
`;

const ButtonPrimaryStyled = styled(ButtonPrimary)`
  font-size: 10px;
  min-width: unset;
  padding: revert;
`;
