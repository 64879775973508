import { useContext } from "react";
import styled from "styled-components";
import { EventContext, AnalyticsContext } from "context";
import { ButtonPrimary } from "Components/Buttons";

export const SetTheSetCTA = ({ modal }) => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const { heading, body, btnLink, btnText } = event.callToActions.setTheSet;

  const setTheSetHandler = () => {
    logClick({
      label: "Set the Set CTA",
      url: btnLink,
    });
  };

  return (
    <SetTheSet modal={modal ? modal : false}>
      <Heading>{heading}</Heading>
      <Body>{body}</Body>
      <StyledBtn
        as="a"
        href={btnLink}
        target="_blank"
        rel="noopener noreferrer"
        onClick={setTheSetHandler}
      >
        {btnText}
      </StyledBtn>
    </SetTheSet>
  );
};

const SetTheSet = styled.div`
  background-color: #e4002b;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;
  margin: ${(props) => (props.modal ? `45px -40px -60px;` : `0 -15px`)};

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
    justify-content: space-between;
    margin: ${(props) => !props.modal && `0`};
  }
`;

const Heading = styled.h3`
  text-transform: uppercase;
  margin: 0;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: 25%;
  }
`;

const Body = styled.p`
  font-size: 12px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: 40%;
    margin: 0 15px;
  }
`;

const StyledBtn = styled(ButtonPrimary)`
  margin-top: 16px;

  &:hover {
    color: #e4002b;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    margin: 0;
  }
`;
