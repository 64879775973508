import { useContext, useState } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext } from "context";
import { PlayIconCircle, Modal, Media16By9, MediaVideo } from "Components";
import { fonts } from "theme/fonts";

export const LocalArtists = ({ postSweeps, postCampaign }) => {
  const { event } = useContext(EventContext);
  const { logVideoAction } = useContext(AnalyticsContext);

  const [open, setOpen] = useState(false);
  const [video, setVideo] = useState(null);

  const setModalVisible = (bool, link) => {
    setVideo(link);
    setOpen(bool);
    logVideoAction({
      bool,
      videoLink: link,
      videoTitle: "Local Artist Video",
    });
  };

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;
  const { artists, body, heading } = event.voting.localArtists;

  const localArtists =
    postSweeps || postCampaign
      ? artists.filter((artist) => !artist.winner)
      : artists;

  localArtists.sort((a, b) => {
    if (a.order < b.order) return -1;
    return 1;
  });

  const localArtistCards = localArtists.map((artist, idx) => {
    const {
      artist: artistName,
      // bio,
      bioVideo,
      image,
      location,
      // social,
      song,
      // winner,
    } = artist;

    const videoHeaderBackground = `${api}/o/${storageDirectory}%2F${image}?alt=media`;

    return (
      <Artist key={idx}>
        <ArtistContainer>
          <VideoHeader background={videoHeaderBackground}>
            <VideoHeaderOverlay>
              <StyledPlayIcon
                small
                onClick={() => setModalVisible(true, bioVideo)}
              />
              <ArtistName>{artistName}</ArtistName>
              <ArtistLocation>{location}</ArtistLocation>
            </VideoHeaderOverlay>
          </VideoHeader>
          <ArtistBody>
            <SpotifyPlayer src={song.spotify} />
          </ArtistBody>
        </ArtistContainer>
      </Artist>
    );
  });

  return (
    <>
      <LocalArtistsWrapper>
        <>
          {heading[0] && <Heading>{ReactHtmlParser(heading[0])}</Heading>}
          {body[0] && <Body>{ReactHtmlParser(body[0])}</Body>}
        </>
        <Artists>{localArtistCards}</Artists>
      </LocalArtistsWrapper>

      <Modal open={open} setOpen={setModalVisible}>
        <Media16By9>
          <MediaVideo
            src={video}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </Media16By9>
      </Modal>
    </>
  );
};

export * from "./WinningArtistCallout";
export * from "./WinningArtistCard";

const LocalArtistsWrapper = styled.div``;

const Heading = styled.h2`
  text-transform: uppercase;
  font-size: 28px;

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    font-size: 36px;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    text-align: center;
  }
`;

const Body = styled.p``;

const Artists = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: space-between;

  &::after {
    content: "";
    width: 48%;
  }

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    justify-content: space-between;

    &::after {
      content: "";
      width: 23%;
    }
  }
`;

const Artist = styled.div`
  width: 48%;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;

  @media only screen and ${(props) => props.theme.maxMedia.large} {
    width: 23%;
  }
`;

const ArtistContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const VideoHeader = styled.div`
  position: relative;
  background-image: url('${(props) => props.background && props.background}');
  background-size: cover;
  background-position: center;
  padding-top: 100%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding-top: 90%;
  }
`;

const VideoHeaderOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #ffffff;
  padding: 15px;
  text-shadow: 1px 1px 2px rgb(0 0 0 / 55%);
`;

const StyledPlayIcon = styled(PlayIconCircle)`
  margin-bottom: 8px;
`;

const ArtistName = styled.h3`
  margin: 0;
  text-transform: uppercase;
  line-height: 1.125;
`;

const ArtistLocation = styled.p`
  font-size: 20px;
  margin: 0;
  font-size: 80%;
  font-family: ${fonts.default};
`;

const ArtistBody = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: #f1f1f1;
  margin-top: 12px;
`;

const SpotifyPlayer = styled.iframe`
  height: 80px;
  width: 100%;
  border: 0;
`;
