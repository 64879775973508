import { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { FieldMessage } from "Components/Form/Fields";
import { Checkbox } from "Components/Form/Fields/Field/Checkbox";

export const OptIn = ({
  onChange,
  value = "",
  field,
  error = {},
  ...props
}) => {
  useEffect(() => {
    if (field.checked && value !== false) {
      onChange({ [field.name]: field.checked });
    }
  }, [field, onChange, value]);

  const handleChange = (value) => {
    onChange({ [field.name]: value });
  };

  return (
    <>
      <Checkbox
        title={ReactHtmlParser(field.label)}
        checked={value}
        error={!!error.status}
        onChange={handleChange}
        {...props}
      />
      <FieldMessage
        active={!!error.status}
        content={error.message}
        type={error.status ? "error" : "success"}
      />
    </>
  );
};
