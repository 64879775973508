import React, { useContext, useState } from "react";
import styled from "styled-components";
import { EventContext, AnalyticsContext } from "context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SocialShareInline = ({ showHeading, vertical, inModal }) => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);
  const [copied, setCopied] = useState(false);

  const { heading, links, secondary } = event.callToActions.share;

  const socialIconHandler = (link) => {
    logClick({
      label: "Social Icon",
      url: link,
    });
  };

  return (
    <Wrapper>
      {showHeading && <Heading>{heading}</Heading>}

      <Nav vertical={vertical}>
        {/* ===== PRIMARY SHARE LINKS ===== */}
        <LinkList vertical={vertical} inModal={inModal}>
          {links.map((item, idx) => (
            <ListItem key={idx} vertical={vertical} inModal={inModal}>
              {item.link ? (
                <Link
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => socialIconHandler(item.link)}
                >
                  <FontAwesomeIcon icon={[item.icon.library, item.icon.name]} />
                </Link>
              ) : (
                item.text
              )}
            </ListItem>
          ))}
        </LinkList>

        {/* ===== SECONDARY SHARE LINKS ===== */}
        <LinkList vertical={vertical} inModal={inModal}>
          {secondary.map((item, idx) => (
            <ListItem key={idx} vertical={vertical} inModal={inModal}>
              {item.link ? (
                <Link
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    e.preventDefault();
                    navigator.clipboard.writeText(item.link);
                    setCopied(true);
                    socialIconHandler(item.link);

                    setTimeout(() => {
                      setCopied(false);
                    }, 5000);
                  }}
                >
                  {copied ? (
                    <FontAwesomeIcon color="#3c943c" icon={["fas", "check"]} />
                  ) : (
                    <FontAwesomeIcon
                      icon={[item.icon.library, item.icon.name]}
                    />
                  )}
                </Link>
              ) : (
                item.text
              )}
            </ListItem>
          ))}
        </LinkList>
      </Nav>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Heading = styled.h3`
  text-transform: uppercase;
  margin-bottom: 12px;
`;

const Nav = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.vertical ? "column" : "row")};
`;

const LinkList = styled.ul`
  display: flex;
  flex-direction: ${(props) => (props.vertical ? "column" : "row")};
  align-items: center;
  list-style: none;
  padding: 0;

  ${(props) =>
    props.inModal &&
    `
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  `}
`;

const ListItem = styled.li`
  padding: ${(props) => (props.vertical ? "12px 0" : "0 7px")};

  ${(props) =>
    props.inModal &&
    `
    padding: 7px;

    &:first-of-type {
      width: 100%;
    }
  `}

  @media ${(props) => props.theme.maxMedia.medium} {
    ${(props) =>
      props.inModal &&
      `
      padding: 0 7px;

      &:first-of-type {
        width: unset;
      }
    `}
  }
`;

const Link = styled.a`
  display: flex;
  font-size: 30px;
  margin: 0 5px;
  background-color: #ffffff;
  color: #005eb8;
  width: 34px;
  height: 34px;
  border-radius: 25px;
  font-size: 20px;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.5;
  }
`;
