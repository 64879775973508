import { RegistrationLayout } from "./";

export const Sweeps = ({ registered = false, formStep = 0, ...props }) => {
  return (
    <RegistrationLayout
      sweeps
      registered={registered}
      formStep={formStep}
      {...props}
    />
  );
};
