const brand = {
  light: "AntennaLight",
  regular: "AntennaRegular",
  regularItalic: "AntennaRegularItalic",
  medium: "AntennaMedium",
  bold: "AntennaBold",
  condensedBold: "AntennaCondensedBold",
};

const base = `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`;
const emoji = "MaxEmoji";

const fallbacks = {
  default: `Roboto, ${base}, ${emoji}`,
  header: `Montserrat, ${base}, ${emoji}`,
};

export const fonts = {
  brand,
  default: `${brand.regular}, ${fallbacks.default}`,
  header: `${brand.medium}, ${fallbacks.header}`,
};
