import { memo } from "react";
import { PhoneField } from "./Phone";
import { RadioField } from "./Radio";
import { TextAreaField } from "./TextArea";
import { DoubleField } from "./DoubleField";
import { EmailField } from "./Email";
import { ZipCode } from "./ZipCode";
import { OptIn } from "./OptIn";
import { Select } from "./Select";
import { TextField } from "./Text";
import { StateField } from "./State";
import { MessageField } from "./Message";
import { DateField } from "./Date";
import { Checkbox } from "./Checkbox";
import { DOBField } from "./DOB";

export const Field = memo(
  ({ field, value, error, onChange, ageGate, ...props }) => {
    switch (field.type) {
      case "select":
        return (
          <Select
            field={field}
            error={error}
            value={value}
            onChange={onChange}
            {...props}
          />
        );
      case "optIn":
        return (
          <OptIn
            field={field}
            error={error}
            value={value}
            onChange={onChange}
            {...props}
          />
        );
      case "zip":
        return (
          <ZipCode
            field={field}
            error={error}
            value={value}
            onChange={onChange}
            {...props}
          />
        );
      case "double":
        return (
          <DoubleField
            field={field}
            error={error}
            value={value}
            onChange={onChange}
            {...props}
          />
        );
      case "email":
        return (
          <EmailField
            field={field}
            error={error}
            value={value}
            onChange={onChange}
            {...props}
          />
        );
      case "phone":
        return (
          <PhoneField
            field={field}
            error={error}
            value={value}
            onChange={onChange}
            {...props}
          />
        );
      case "radio":
        return (
          <RadioField
            field={field}
            error={error}
            value={value}
            onChange={onChange}
            {...props}
          />
        );
      case "textarea":
        return (
          <TextAreaField
            field={field}
            error={error}
            value={value}
            onChange={onChange}
            {...props}
          />
        );
      case "text":
        return (
          <TextField
            field={field}
            error={error}
            value={value}
            onChange={onChange}
            ageGate={ageGate}
            {...props}
          />
        );
      case "state":
        return (
          <StateField
            field={field}
            error={error}
            value={value}
            onChange={onChange}
            ageGate={ageGate}
            {...props}
          />
        );
      case "date":
        return (
          <DateField
            field={field}
            error={error}
            value={value}
            onChange={onChange}
            ageGate={ageGate}
            {...props}
          />
        );
      case "checkbox":
        return (
          <Checkbox
            field={field}
            error={error}
            value={value}
            onChange={onChange}
            {...props}
          />
        );
      case "dob":
        return (
          <DOBField
            field={field}
            error={error}
            value={value}
            onChange={onChange}
            ageGate={ageGate}
            {...props}
          />
        );
      case "message":
        return <MessageField field={field} {...props} />;
      default:
        break;
    }
  }
);
