import styled from "styled-components";
import { Radio, GridColumn } from "notes";
import { FieldMessage } from "Components";

export const RadioField = ({ onChange, value, field, error = {} }) => {
  const handleRadio = (value) => {
    onChange({ [field.name]: value });
  };

  return (
    <GridColumn>
      <FieldMessage active={!!error.status} content={error.message} />
      {field.options?.map(({ id, title, subtitle }) => (
        <InlineRadio
          key={id}
          checked={value === title}
          onChange={() => handleRadio(title)}
          title={!!subtitle && title}
        >
          <StyledLabel>{!!subtitle ? subtitle : title}</StyledLabel>
        </InlineRadio>
      ))}
    </GridColumn>
  );
};

const StyledLabel = styled.p`
  color: #ffffff;
  margin: 5px 0;
  font-size: 12px;
`;

const InlineRadio = styled(Radio)`
  margin-top: 0;
  margin-bottom: 0;
  #outer {
    stroke: #fff;
    fill: #fff;
  }
  #inner {
    fill: #102b4e;
  }
`;
