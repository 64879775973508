import React, { createContext, useState, useEffect } from "react";
import { DateTime } from "luxon";
import { firebase } from "context";

export const TimeContext = createContext();
export const TimeConsumer = TimeContext.Consumer;
export const TimeProvider = ({ children }) => {
  const [serverOffset, setServerOffset] = useState(0);
  const [localTime, setLocalTime] = useState(Date.now());
  const time = new DateTime.fromMillis(localTime + serverOffset);

  useEffect(() => {
    firebase
      .database()
      .ref("/.info/serverTimeOffset")
      .once("value")
      .then(
        function stv(data) {
          setServerOffset(data.val());
        },
        function (err) {
          return err;
        }
      );
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setLocalTime(Date.now());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <TimeContext.Provider value={{ time }}>{children}</TimeContext.Provider>
  );
};
