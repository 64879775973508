import { useContext } from "react";
import { EventContext } from "context";
import { useParams, useLocation } from "react-router-dom";

export const Calendar = () => {
  const location = useLocation();
  const {
    event: { id, start, end, url, email },
  } = useContext(EventContext);
  const title = email?.heading;

  // Get calendar type from url path
  const { type } = useParams();

  // Grab token from query string
  const params = new URLSearchParams(location.search);
  const token = params.get("token");

  const eventUrl = new URL(url);

  const generateiCal = () => {
    // Construct link to the Cloud Function that will generate the iCal file
    const cloudUrl = new URL(`https://${eventUrl.hostname}/calendar`);

    cloudUrl.searchParams.append("t", "ical");
    cloudUrl.searchParams.append("e", id);
    cloudUrl.searchParams.append("token", token);

    window.location.href = cloudUrl.href;

    return null;
  };

  const generateGoogleCal = () => {
    // Construct event url with token
    eventUrl.searchParams.append("token", token);

    const googleCalendarUrl = new URL(
      "https://calendar.google.com/calendar/r/eventedit"
    );

    const re = /-|:|\./g;
    const startTime = start.toUTC().toISO().replace(re, "");
    const endTime = end.toUTC().toISO().replace(re, "");

    googleCalendarUrl.searchParams.append("text", title);
    googleCalendarUrl.searchParams.append("location", eventUrl.href);
    googleCalendarUrl.searchParams.append("dates", `${startTime}/${endTime}`);

    window.location.href = googleCalendarUrl.href;

    return null;
  };

  if (type === "google") {
    generateGoogleCal();
  } else {
    generateiCal();
  }

  return null;
};
