import React, { useState } from "react";
import styled from "styled-components";
import InputMask from "react-input-mask";
import { FlexBox } from "notes";
import { Input } from "Components";

const Container = styled(FlexBox)`
  position: relative;
`;

export const DateMask = ({
  date: value,
  onChange,
  placeholder,
  ageGate,
  ...props
}) => {
  const [focus, setFocus] = useState(false);

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    let { value, selection } = newState;
    if (newState.value.length === oldState.value.length) {
      if (selection?.start === 3 || selection?.start === 6) {
        selection = {
          start: selection?.start - 1,
          end: selection?.start - 1,
        };
      }
    }
    return { value, selection };
  };

  return (
    <Container>
      <InputMask
        alwaysShowMask={false}
        maskChar=""
        mask="99/99/9999"
        value={value}
        onChange={(value) => onChange(!!value.length ? value : null)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        beforeMaskedValueChange={beforeMaskedValueChange}
      >
        {() => (
          <Input
            type="text"
            inputmode="numeric"
            data-javelin-name={`input-date`}
            data-javelin-meta={JSON.stringify(value)}
            placeholder={focus ? "" : placeholder}
            maxLength={10}
            ageGate={ageGate}
            {...props}
          />
        )}
      </InputMask>
    </Container>
  );
};
