import { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { AnalyticsContext, EventContext } from "context";
import { ButtonPrimary } from "Components/Buttons";

export const ExternalLivestream = () => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;

  const {
    heading,
    body,
    btnText,
    btnLink,
    image,
  } = event.callToActions.externalLivestream;

  let desktopImage = `${api}/o/${storageDirectory}%2F${image.desktop}?alt=media`;
  let mobileImage = `${api}/o/${storageDirectory}%2F${image.mobile}?alt=media`;

  const ctaClickHandler = () => {
    logClick({
      label: "External Livestream",
      url: btnLink,
    });
  };

  return (
    <Wrapper>
      <ArtistImage
        desktopBackground={desktopImage}
        mobileBackground={mobileImage}
      />
      <Content>
        <Heading>{ReactHtmlParser(heading)}</Heading>
        <Body>{ReactHtmlParser(body)}</Body>
        <ButtonPrimary
          as="a"
          href={btnLink}
          onClick={ctaClickHandler}
          target="_blank"
          rel="noopener noreferrer"
        >
          {btnText}
        </ButtonPrimary>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin: 25px -15px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
    margin: 25px 0;
  }
`;

const ArtistImage = styled.div`
  padding-top: 56.25%;

  ${(props) => `
    background-image: url(${props.mobileBackground});
    background-size: cover;
    background-position: center;
  `}

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: 55%;
    padding-top: 0;
    ${(props) => ` background-image: url(${props.desktopBackground});`}
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1336bf;
  padding: 50px 25px;
  text-align: center;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex: 1;
    padding: 50px;
  }
`;

const Heading = styled.h1`
  text-transform: uppercase;
  font-size: 44px;
  margin-bottom: 0px;
`;

const Body = styled.p`
  margin-bottom: 32px;
  text-transform: uppercase;
`;
