import { useContext } from "react";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import { EventContext, AnalyticsContext } from "context";
import { ButtonPrimary } from "Components/Buttons";

export const HubPageCTA = ({ buttonText, marginTop }) => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const { btnLink, btnText, heading } = event.callToActions.hub;

  const ctaClickHandler = () => {
    logClick({
      label: "Hub Page CTA",
      url: btnLink,
    });
  };

  return (
    <Wrapper marginTop={marginTop}>
      {heading && <Heading>{ReactHtmlParser(heading)}</Heading>}
      <ButtonPrimary
        as="a"
        href={btnLink}
        onClick={ctaClickHandler}
        target="_blank"
        rel="noopener noreferrer"
      >
        {buttonText
          ? ReactHtmlParser(btnText[buttonText])
          : ReactHtmlParser(btnText[0])}
      </ButtonPrimary>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  ${(props) => props.marginTop && `margin-top: 25px;`}
`;

const Heading = styled.h2`
  color: #ffffff;
  line-height: 1;
  margin-bottom: 12px;
`;
