import React, { createContext } from "react";
import { DateTime } from "luxon";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { firebase } from "context";

export const EventContext = createContext();
export const EventConsumer = EventContext.Consumer;

export const EventProvider = ({ children }) => {
  const [event, loading, error] = useDocumentData(
    firebase.firestore().doc(`events/${process.env.REACT_APP_EVENT_ID}`)
  );

  if (loading) return null;

  event.id = process.env.REACT_APP_EVENT_ID;
  event.start = DateTime.fromJSDate(event.start.toDate());
  event.end = DateTime.fromJSDate(event.end.toDate());
  event.countdown = DateTime.fromJSDate(event.startCountdown.toDate());
  event.endSweeps = DateTime.fromJSDate(event.endSweeps.toDate());
  event.campaignStart = DateTime.fromJSDate(event.campaignStart.toDate());
  event.campaignEnd = DateTime.fromJSDate(event.campaignEnd.toDate());
  event.votingEnd = DateTime.fromJSDate(
    event.transitionTimes.votingEnd.toDate()
  );

  return (
    <EventContext.Provider value={{ event, loading, error }}>
      {children}
    </EventContext.Provider>
  );
};
