import React, { useContext } from "react";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import { EventContext, AnalyticsContext } from "context";
import { fonts } from "theme/fonts";

export const AddToCalendar = () => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const { heading, links } = event.callToActions.addToCalendar;

  const ctaClickHandler = (link) => {
    logClick({
      label: "Add to Calendar",
      url: link,
    });
  };

  return (
    <Wrapper>
      <h2>{ReactHtmlParser(heading)}</h2>

      <CalendarLinks>
        {links.map((item, idx) => (
          <li key={idx}>
            <a
              href={item.link}
              onClick={() => ctaClickHandler(item.link)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {ReactHtmlParser(item.text)}
            </a>
          </li>
        ))}
      </CalendarLinks>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 32px;

  h2 {
    font-family: ${fonts.brand.medium};
    color: #ffffff;
    line-height: 1;
    margin-bottom: 12px;
  }
`;

const CalendarLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;

  li {
    margin: 0 15px 10px 0;

    @media only screen and ${(props) => props.theme.maxMedia.medium} {
      margin: 0 15px 0 0;
    }
  }

  a {
    width: 100%;
    background-color: #fec82e;
    color: #ffffff;
    padding: 10px 15px;
    text-decoration: none;
    border-radius: 5px;
    font-family: ${fonts.header};
    font-size: clamp(18px, 3vw, 24px);
    text-transform: uppercase;
    display: inline-block;
    letter-spacing: 2px;

    @media only screen and ${(props) => props.theme.maxMedia.medium} {
      padding: 10px 35px;
    }
  }

  a:hover,
  a:focus {
    background-color: #ebaa00;
  }
`;
